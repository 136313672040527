<template>
  <div>
    <b-table
      responsive="sm"
      :fields="fields"
      :items="items"
      table-class="table-head-custom mt-5"
      show-empty
      :busy="isBusy"
    >
      <!-- begin:: Cell Template     -->
      <template v-slot:cell(index)="data">
        <span class="font-weight-bolder text-muted">{{ data.index + 1 }}</span>
      </template>

      <!-- begin:: Leave Name Cell     -->
      <template v-slot:cell(name)="data">
        <div class="d-flex flex-column">
          <span class="font-weight-bolder text-h6">
            {{ data.item.name }}
          </span>
        </div>
      </template>
      <!-- end:: Leave Name Cell     -->

      <!-- begin:: Status Cell     -->
      <template v-slot:cell(status)="data">
        <span
          class="label label-lg font-weight-bold label-light-info label-inline"
          >{{ data.item.status }}</span
        >
      </template>
      <!-- end:: Status Cell     -->
      <!-- end:: Cell Template     -->

      <!-- begin:: Table Template     -->
      <template #table-busy>
        <AppLoader #table-busy></AppLoader>
      </template>

      <template #empty>
        <AppEmptyList
          text-top="You have no existing applications"
          text-bottom=".."
        ></AppEmptyList>
      </template>
      <!-- end:: Table Template     -->
    </b-table>

    <div class="d-flex justify-content-end" v-if="items.length && !isBusy">
      <b-pagination
        pills
        size="lg"
        align="right"
        class="pt-5"
        @change="refresh"
        v-model="pagination.current_page"
        :total-rows="pagination.total"
        :per-page="pagination.per_page"
        aria-controls="employee-page-table"
      ></b-pagination>
    </div>
  </div>
</template>

<script>
import AppLoader from "@/modules/core/components/loaders/AppLoader";
import AppEmptyList from "@/modules/core/components/empty/AppEmptyList";
import { mapGetters } from "vuex";

export default {
  name: "LeavePageApplicationTable",
  props: ["reload"],
  components: {
    AppEmptyList,
    AppLoader,
  },
  mounted() {
    this.fetch();
  },
  data() {
    return {
      perPage: 10,
      currentPage: 1,
      fields: [
        { key: "index", label: "#" },
        { key: "name", label: this.$t("LEAVE.TYPE") },
        { key: "from_date", label: this.$t("EDUCATION.FROM") },
        { key: "to_date", label: this.$t("EDUCATION.TO") },
        { key: "status", label: this.$t("JOB.STATUS") },
      ],
      isBusy: false,
    };
  },

  methods: {
    cancelLeaveApplication() {},

    fetch() {
      this.isBusy = true;
      this.$store
        .dispatch("fetchPersonalLeaveApplicationList")
        .finally(() => (this.isBusy = false));
    },

    refresh(currentPage) {
      console.log("Current Page is", currentPage);
      this.$store.commit("setPersonalLeaveApplicationPage", currentPage);
      this.fetch();
    },
  },

  computed: {
    ...mapGetters({
      items: "getPersonalLeaveApplicationList",
      pagination: "getPersonalLeaveApplicationListPagination",
    }),
  },
};
</script>

<style scoped></style>
